
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n/index";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import ApiService from "@/core/services/ApiService";

interface IBetSummary {
  betamount: string;
  game: string;
  members: string;
  sonic: string;
  winamount: string;
}

interface DateRange {
  start: Date;
  end: Date;
}

export default defineComponent({
  name: "BetSummary",
  components: {
    Datatable,
    DateRangePicker,
  },
  setup() {
    const { t } = useI18n();
    const tableHeader = ref([
      {
        name: t("Game.Game"),
        key: "game",
        sortable: true,
      },
      {
        name: t("BetSummary.BetAmount"),
        key: "betamount",
        sortable: true,
        currency: true,
      },
      {
        name: t("BetSummary.WinAmount"),
        key: "winprice",
        sortable: true,
        currency: true,
      },
      {
        name: t("BetSummary.Profit"),
        key: "sonic",
        sortable: true,
        currency: true,
      },
      {
        name: t("BetSummary.MemberCount"),
        key: "members",
        currency: true,
      },
    ]);
    const tableData = ref<Array<IBetSummary>>([]);
    /**
     * Get Bet Summary
     *
     */
    const getBetSummary = async (date: DateRange) => {
      const results = await ApiService.get(
        `/dashboard/bet-summary?start=${date.start}&end=${date.end}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
    };
    return {
      tableData,
      tableHeader,
      getBetSummary,
    };
  },
});
