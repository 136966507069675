import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-5" }
const _hoisted_3 = { class: "card-title align-items-start flex-column" }
const _hoisted_4 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_5 = { class: "card-toolbar" }
const _hoisted_6 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = {
  href: "#",
  class: "text-gray-600 text-hover-primary mb-1"
}
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangePicker = _resolveComponent("DateRangePicker")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("Game.AlMonthlyUsage")), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_DateRangePicker, { onChangedate: _ctx.getAlUsage }, null, 8, ["onChangedate"])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_Datatable, {
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        "enable-items-per-page-dropdown": true
      }, {
        "cell-name": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.name), 1)
        ]),
        "cell-email": _withCtx(({ row: customer }) => [
          _createElementVNode("a", _hoisted_8, _toDisplayString(customer.email), 1)
        ]),
        "cell-company": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.company), 1)
        ]),
        "cell-paymentMethod": _withCtx(({ row: customer }) => [
          _createElementVNode("img", {
            src: customer.payment.icon,
            class: "w-35px me-3",
            alt: ""
          }, null, 8, _hoisted_9),
          _createTextVNode(_toDisplayString(customer.payment.ccnumber), 1)
        ]),
        "cell-date": _withCtx(({ row: customer }) => [
          _createTextVNode(_toDisplayString(customer.date), 1)
        ]),
        _: 1
      }, 8, ["table-data", "table-header"])
    ])
  ]))
}