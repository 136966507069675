
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import DateRangePicker from "@/components/DateRangePicker.vue";
import ApiService from "@/core/services/ApiService";

interface IGameAlUsage {
  game: string;
  sonic: string;
}

export default defineComponent({
  name: "customers-listing",
  components: {
    Datatable,
    DateRangePicker,
  },
  setup() {
    const { t } = useI18n();
    const tableHeader = ref([
      {
        name: t("Game.Game"),
        key: "game",
        sortable: true,
      },
      {
        name: t("Game.AlUsageAmount"),
        key: "sonic",
        sortable: true,
        currency: true,
      },
    ]);

    const tableData = ref<Array<IGameAlUsage>>([]);
    /**
     * Get Bet Summary
     *
     */
    const getAlUsage = async (date) => {
      const results = await ApiService.get(
        `/dashboard/game-al-usage?start=${date.start}&end=${date.end}`
      )
        .then((res) => res.data)
        .catch(() => []);
      tableData.value.splice(0, tableData.value.length, ...results);
    };
    return {
      tableData,
      tableHeader,
      getAlUsage,
    };
  },
});
