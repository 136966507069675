
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n/index";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "DashboardSummary",
  setup() {
    const { t } = useI18n();

    const currentAl = reactive({ casino: 0, slot: 0 });

    const items = reactive([
      {
        icon: "/media/icons/duotune/maps/map004.svg",
        title: t("DashboardSummary.Members"),
        description: "Members",
        stats: 0,
        arrow: "up",
      },
      {
        icon: "/media/icons/duotune/general/gen024.svg",
        title: t("DashboardSummary.MonthCasinoLoseAmount"),
        description: "Casino Total Losings (This Month)",
        stats: 0,
        arrow: "down",
      },
      {
        icon: "/media/icons/duotune/electronics/elc005.svg",
        title: t("DashboardSummary.MonthCasinoWinAmount"),
        description: "Casino Total Winnings (This Month)",
        stats: 0,
        arrow: "up",
      },
      {
        icon: "/media/icons/duotune/general/gen005.svg",
        title: t("DashboardSummary.MonthCasinoAlUsage"),
        description: "Casino Credit Usage (This Month)",
        stats: 0,
        arrow: "up",
      },
    ]);

    const getDashboardSummary = async () => {
      const summary = await ApiService.get("/dashboard/summary").then(
        (res) => res.data
      );
      // set al
      currentAl.slot = summary.al_slot;
      currentAl.casino = summary.al_wallet;
      // set item values
      items[0].stats = summary.members; // members
      items[1].stats = summary.casino.loseamount; // loseamount
      items[2].stats = summary.casino.winamount; // winamount
      items[3].stats = summary.casino.sonic; // al usage
    };

    return {
      items,
      currentAl,
      getDashboardSummary,
    };
  },
  mounted() {
    this.getDashboardSummary();
  },
});
