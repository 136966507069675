
import { defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import DashboardSummary from "@/components/dashboard/DashboardSummary.vue";
import GameAlUsage from "@/components/dashboard/GameAlUsage.vue";
import BetSummary from "@/components/dashboard/BetSummary.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    DashboardSummary,
    GameAlUsage,
    BetSummary,
  },
  setup() {
    const { t } = useI18n();

    onMounted(() => {
      setCurrentPageTitle(t("Menu.Dashboard"));
    });
  },
});
