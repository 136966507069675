import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-6" }
const _hoisted_3 = { class: "col-xxl-6" }
const _hoisted_4 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DashboardSummary = _resolveComponent("DashboardSummary")!
  const _component_GameAlUsage = _resolveComponent("GameAlUsage")!
  const _component_BetSummary = _resolveComponent("BetSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_DashboardSummary)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_GameAlUsage)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_BetSummary)
    ])
  ]))
}